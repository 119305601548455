/* Color palette */
$red: #990000;
$red-light: #006699;
$orange: #7ebc6f;
$blue-dark: #2c3038;
$blue-light: #929db3;
$blue-grey: #68707f;
$washed-blue: rgba(146, 157, 179, 0.1);
$grey-light: #e1e0e0;
$tan: #f0efef;
$white: #fff;
